/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkflowTaskActionControls {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
}
